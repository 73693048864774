
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import DTCls from "@/utils/datetime";
import { countryNoComList } from "@/utils";
import { saveAs } from "@/api/request/base";
import Pagination from "@/comp/Pagination/index.vue";
import { getBloggerRecommendList, getBloggerRecommendExport, setBloggerRecommendStatus, saveBloggerRecommend } from "@/api/request/blogger";

//组件
@Component({
  name: "PostRecommendSet",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private countryList: any[] = [];
  private listLoading: boolean = false;

  //请求数据
  private listQuery: any = {
    //页码
    page: 1,
    page_size: 20,

    //临时数据
    sort_str: "",
    blogger_id_str: "",

    //数据
    sort: 0, //排序参数
    country: "",
    blogger_id: 0, //博主ID
    blogger_nick_name: "", //博主昵称

    //状态数据
    identity: -1, //博主身份 -1:全部, 0:正常, 1:内部
    status: -1, //配置状态 -1:全部, 1:启用, 2:停用, 3:删除
    daily_status: -1, //帖子状态 ：-1:全部, 1-正常，2-失效
  };

  //时间选择器参数
  get pickerOptions(): any {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
          },
        },
      ],
      disabledDate(time: Date) {
        return time.getTime() > Date.now();
      },
    };
  }

  //配置状态列表
  private statusList: any[] = [
    { id: -1, name: "全部配置状态" },
    { id: 1, name: "启用" },
    { id: 2, name: "停用" },
    { id: 3, name: "删除" },
  ];

  //博主身份列表
  private identityList: any[] = [
    { id: -1, name: "全部博主身份" },
    { id: 0, name: "正常" },
    { id: 1, name: "内部" },
  ];

  //帖子状态列表
  private dailyStatusList: any[] = [
    { id: -1, name: "全部帖子状态" },
    { id: 1, name: "正常" },
    { id: 2, name: "失效" },
  ];

  //创建
  created() {
    //数据赋值
    this.countryList = countryNoComList;

    //获取列表
    this.getList();
  }

  //处理添加
  private handlerAdd(): void {
    //数据赋值
    this.form = {
      //临时数据
      sort_str: "", //排序
      daily_id_str: "", //帖子ID
      blogger_id_str: "", //博主ID

      //数据
      id: 0, //ID
      sort: 0, //排序
      daily_id: 0, //帖子ID
      blogger_id: 0, //博主ID
    };

    //显示界面
    this.dialogVisible = true;
  }

  //获取列表
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.sort = Number(this.listQuery.sort_str);
    this.listQuery.blogger_id = Number(this.listQuery.blogger_id_str);

    //获取数据
    const { data } = await getBloggerRecommendList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleSearch(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //处理导出
  private async handlerExport() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.sort = Number(this.listQuery.sort_str);
    this.listQuery.blogger_id = Number(this.listQuery.blogger_id_str);

    //获取数据
    const { data } = await getBloggerRecommendExport(this.listQuery);

    //保存数据
    saveAs(data, "博主帖子推荐配置列表");

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理编辑
  private handleEdit(row: any): void {
    //数据赋值
    this.form = {
      //临时数据
      sort_str: String(row.sort), //排序
      daily_id_str: String(row.daily_id), //帖子ID
      blogger_id_str: String(row.blogger_id), //博主ID

      //数据
      id: row.id, //ID
      sort: row.sort, //排序
      daily_id: row.daily_id, //帖子ID
      blogger_id: row.blogger_id, //博主ID
    };

    //显示界面
    this.dialogVisible = true;
  }

  //获取归属国家名称
  private getCountryName(country: string): string {
    //没有国家属性
    if (country == "") {
      return "-";
    }

    //判断国家
    for (var i: number = 0; i < this.countryList.length; i++) {
      if (country == this.countryList[i].country) {
        return this.countryList[i].name;
      }
    }

    //反馈空
    return "";
  }

  //处理状态
  private async handleStatus(row: any, status: number) {
    //显示等待
    this.listLoading = true;

    //处理修改状态
    await setBloggerRecommendStatus({ id: row.id, status: status });

    //隐藏等待
    setTimeout(() => {
      //获取列表
      this.getList();
    }, 0.5 * 1000);
  }

  //-------------------------------- 添加/编辑 任务 ------------------------------
  //定义变量
  private dialogVisible: boolean = false;
  private dialogLoading: boolean = false;

  //表单数据
  private form: any = {
    //临时数据
    sort_str: "", //排序
    daily_id_str: "", //帖子ID
    blogger_id_str: "", //博主ID

    //数据
    id: 0, //ID
    sort: 0, //排序
    daily_id: 0, //帖子ID
    blogger_id: 0, //博主ID
  };

  //取消按钮
  private btnCancel(): void {
    //隐藏界面
    this.dialogVisible = false;
  }

  //保存按钮
  private async handlePush() {
    //数据处理
    if (Number(this.form.daily_id_str) == 0) return this.$message.error("请输入正确的帖子ID");
    if (Number(this.form.blogger_id_str) == 0) return this.$message.error("请输入正确的博主ID");

    //数据赋值
    this.form.sort = Number(this.form.sort_str);
    this.form.daily_id = Number(this.form.daily_id_str);
    this.form.blogger_id = Number(this.form.blogger_id_str);

    //保存任务
    await saveBloggerRecommend(this.form);

    //隐藏等待
    setTimeout(() => {
      //隐藏加载
      this.dialogLoading = false;

      //隐藏对话框
      this.dialogVisible = false;

      //加载列表
      this.getList();
    }, 0.5 * 1000);
  }
}
